import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { EntityUserHistoryEntry, HistoryAddedField, HistoryModifiedField } from '@noldortech/plex-utilities';
import { map, take, finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';

import { User } from './user.model';
import { AuditLogService } from '../audit-log/audit-log.service';
import { environment } from '../../../../../../environments/environment';
import { RequestAccount } from '../../../../../auth/components/request-user-account/request-user-account.model';
import { Watcher } from '../tasks/models/watcher.model';
import { Account } from './../fin/models/account';
import { AuthenticationService } from '../../../../../auth/_services';
import { Property } from '@plex/property_models';
import { Entity } from '../entities/entities.model';
import { selectEntityId } from 'src/app/_state/entity/entity.selectors';

declare var toastr: any;

export type Product = 'whitfields' | 'plex';

@Injectable()
export class UsersService {
	usersCollection: AngularFirestoreCollection<User[]>;
	entityUsersCollection: AngularFirestoreCollection<any>;
	userDoc: AngularFirestoreDocument<User>;
	user = new Subject<User>();
	currentUser: User;
	currentUsers: User[];
	propertiesCollection: AngularFirestoreCollection<Property[]>;
	propertyDoc: AngularFirestoreDocument<Property>;
	userEntityDoc: AngularFirestoreDocument<User>;
	propertyOtherDoc: AngularFirestoreDocument<Property>;
	permissionDoc: AngularFirestoreDocument<any>;
	managementUsersList: Observable<any>;
	task: AngularFireUploadTask;
	userId: any;
	currentStep: number;
	entityId: string;

	constructor(
		private auditLogService: AuditLogService,
		public afs: AngularFirestore,
		public router: Router,
		private auth: AuthenticationService,
		private storage: AngularFireStorage,
		private functions: AngularFireFunctions,
		private authSrvc: AuthenticationService,
		private store: Store
	) {
		this.store.select(selectEntityId).subscribe(entityId => (this.entityId = entityId));
	}

	public userEntities$(userId: string, product: Product): Observable<Entity[]> {
		return this.afs
			.collection(`users/${userId}/entities`, ref => {
				return ref.where('active', '==', true).where('product', '==', product).orderBy('name', 'asc');
			})
			.valueChanges({ idField: 'id' });
	}

	fetchUserProperties(userId: string) {
		return this.afs
			.collection('lookup/properties/list', ref => ref.where('users', 'array-contains', userId).where('active', '==', true).where(`usersData.${userId}.active`, '==', true))
			.valueChanges({ idField: 'id' });
	}

	fetchUserAccounts(userId: string) {
		return this.afs
			.collection('lookup/accounts/list', ref => ref.where('users', 'array-contains', userId).where('active', '==', true).where(`usersData.${userId}.active`, '==', true))
			.valueChanges({ idField: 'id' });
	}

	async addUser(userData) {
		return await this.functions
			.httpsCallable('user-core-addUser')({
				userData,
				entityId: this.entityId,
				environment,
			})
			.toPromise();
	}

	fetchEntityUsers(active: boolean) {
		this.entityUsersCollection = this.afs.collection(`entities/${this.entityId}/users`, ref => ref.where('active', '==', active));
		return this.entityUsersCollection.snapshotChanges().pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.payload.doc.data() as any;
					data.uid = a.payload.doc.id;
					data.full_name = `${data.firstname} ${data.surname}`;
					return data;
				});
			})
		);
	}

	fetchEntityUnregisteredUsers() {
		return this.afs.collection(`entities/${this.entityId}/users`, ref => ref.where('isRegistered', '==', false)).valueChanges({ idField: 'id' });
	}

	fetchEntityUnverifiedUsers() {
		return this.afs
			.collection(`entities/${this.entityId}/users`, ref => ref.where('isRegistered', '==', true).where('isVerified', '==', false))
			.valueChanges({ idField: 'id' });
	}

	setEntityUserActivity(user: User, active: boolean, entityId?: string) {
		const entityRef = this.afs.collection('entities').doc(this.entityId).ref;
		const entityUserRef = this.afs.collection('entities').doc(this.entityId).collection('users').doc(user.uid);
		const userEntityRef = this.afs.collection('users').doc(user.uid).collection('entities').doc(this.entityId);
		const userEntityPropertiesRef = this.afs.collection('users').doc(user.uid).collection('entities').doc(this.entityId).collection('properties').ref;
		// const userManagementRef = this.afs.collection('entities').doc(entityID).collection('management').doc('users').collection('list').doc(user.uid);

		//REMOVE USER FROM ENTITY
		const updateEntityUser = entityUserRef.update({
			active,
		});

		// REMOVE ENTITY FROM USER
		const updateUserEntity = userEntityRef.update({
			permissions: [], // THIS WILL TRIGGER CLOUD FUNCTION TO REMOVE FROM MANAGEMENT TEAM
			active,
		});

		// GET USER PROPERTIES
		const updateUserEntityProperties = userEntityPropertiesRef.get().then(properties => {
			return properties.forEach(property => {
				const propertyData = property.data();

				return this.removePropertyFromUser(user.uid, propertyData.uid, propertyData.type);
			});
		});

		return Promise.all([updateEntityUser, updateUserEntity, updateUserEntityProperties]).then(() => {
			toastr.success(`User has successfully been ${active ? 'made active' : 'removed'}`);
			let logData = {
				name: user.email.toLowerCase(),
				description: `User was ${active ? 'made active' : 'removed'}`,
				type: `${active ? 'add' : 'remove'}`,
				category: 'user',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	fetchUser(userId: string) {
		this.userDoc = this.afs.doc(`users/${userId}`);
		return this.userDoc.valueChanges({ idField: 'uid' });
	}

	// TODO: this function should ideally be replaced with a set of functions that have a corresponding security rule
	/**
	 * @deprecated DO NOT USE THIS FUNCTION IN NEW FUNCTIONALITY - passing in a ref for this is a pointless exercise when you can subscribe to the result directly
	 * @param ref
	 */
	fetchUserDetails(ref: string) {
		// TODO: places where this function is used needs to be evaluated - for getting the current logged in user's data it is fine - consider a new function named 'currentUserDetails'
		// TODO: - where this function is used to retrieve other user's data need to be refactored to read from a collection that doesn't contain personal info or a cloud function
		this.userDoc = this.afs.doc(ref);
		return this.userDoc.valueChanges({ idField: 'uid' });
	}

	fetchUserRefDetails(firebaseId: string) {
		const userRefsRef = this.afs.doc(`userRefs/${firebaseId}`);
		return userRefsRef.valueChanges();
	}

	fetchUserDetailsByEmail(email: string) {
		this.usersCollection = this.afs.collection('users', ref => ref.where('email', '==', email));

		return this.usersCollection.valueChanges();
	}

	setEditUser(refPath: string) {
		this.fetchUserDetails(refPath).subscribe(userDetails => {
			userDetails.ref = refPath;
			this.user.next(userDetails);
			this.currentUser = userDetails;
			this.router.navigate([`/${this.entityId}/users/edit`]);
		});
	}

	updateUserProfile(user: User) {
		this.userDoc = this.afs.collection('users').doc(user.uid);
		user.email = user.email.toLowerCase();
		return this.userDoc.update(user).then(() => {
			toastr.success('User Profile has been updated!');
			let logData = {
				userId: user.uid,
				name: user.email.toLowerCase(),
				description: 'User was updated',
				type: 'update',
				category: 'user',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		});
	}

	async updateUser(user: User, entityId?: string) {
		const usersWithSameEmail = await this.afs.collection('users').ref.where('email', '==', user.email).where('uid', '!=', user.uid).get();

		// if product is whitfields, we want to check if the email exists under /entities/whitfields/users
		if (environment.product === 'whitfields') {
			const whitfieldsUsersWithSameEmail = await this.afs.collection('entities/whitfields/users').ref.where('email', '==', user.email).where('uid', '!=', user.uid).get();
			if (!whitfieldsUsersWithSameEmail.empty) {
				throw 'Email already exists as staff member!';
			}
		}

		if (!usersWithSameEmail.empty) {
			throw 'Email already exists!';
		}

		this.userDoc = this.afs.collection('users').doc(user.uid);
		user.email = user.email.toLowerCase();

		try {
			if (entityId) {
				await this.afs.doc(`entities/${entityId}/users/${user.uid}`).set(user, { merge: true });
			}

			await this.userDoc.update(user);
			toastr.success('User Profile has been updated!');
		} catch (error) {
			console.error(error);
			throw error;
		}

		try {
			let logData = {
				userId: user.uid,
				name: user.email.toLowerCase(),
				description: 'User was updated',
				type: 'update',
				category: 'user',
				created: Date.now(),
			};
			this.auditLogService.addAudit(logData);
		} catch (error) {
			console.error('Error updating audit log', error);
			throw `Error updating audit log`;
		}
	}

	updateUserPropertyDetails(propertyData, userUID) {
		this.propertyDoc = this.afs.collection('users').doc(userUID).collection('entities').doc(this.entityId).collection('properties').doc(propertyData.uid);
		//CHECK TO UPDATE ON BOTH PLACES
		this.propertyOtherDoc = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(propertyData.uid).collection('users').doc(userUID);
		this.propertyOtherDoc.update(propertyData);
		return this.propertyDoc.update(propertyData).then(() => {
			toastr.success('Property has been updated!');
		});
	}

	public async addHistoryLogToUser(
		changed: Array<HistoryAddedField | HistoryModifiedField>,
		historyType: 'properties' | 'accounts' | 'notes' | 'userDetails' | 'customFields',
		userId: string,
		notifyUpdates: boolean,
		entityID = this.entityId
	): Promise<DocumentReference> {
		const loggedInUserId: string = sessionStorage.getItem('userId');
		const propertyHistoryCollection: AngularFirestoreCollection = this.afs.collection(`entities/${entityID}/users/${userId}/history`);
		const userDetails = this.authSrvc.userDetails;

		const logData: EntityUserHistoryEntry = {
			userId,
			historyType,
			created: Date.now(),
			changed,
			changedBy: loggedInUserId,
			changedByName: `${userDetails.firstname} ${userDetails.surname}`,
			client: environment.client,
			admin: environment.admin,
			product: environment.product,
			entityId: entityID,
		};

		try {
			if (environment.product === 'whitfields') {
				this.addPendingUserUpdates(logData);
			} else {
				if (notifyUpdates) this.addPendingUserUpdates(logData);
			}
			return await propertyHistoryCollection.add(logData);
		} catch (error) {
			throw error;
		}
	}

	addPendingUserUpdates(logData) {
		const pendingUpdatesCollection = this.afs.collection(`pending`);
		logData.request = 'notifyReceiveUserUpdates';
		logData.created = new Date();

		// Remove Task Notification on Occupant https://noldor.atlassian.net/browse/AM-1387
		const filteredChangedLogdata = logData.changed.filter(({ field }) => {
			return field !== 'occupant';
		});

		if (filteredChangedLogdata.length || environment.product !== 'whitfields') {
			pendingUpdatesCollection.add(logData).catch(err => {
				console.log(err);
			});
		}
	}

	sendUserAddedNotification(userDetails) {
		const pendingUserAddNotificationRef = this.afs.collection('pending');
		const userInfo = this.authSrvc.userDetails;

		pendingUserAddNotificationRef.add({
			request: 'emailUserAddedNotification',
			userName: `${userDetails.firstname} ${userDetails.surname}`,
			email: userDetails.email,
			entityId: this.entityId,
			environmentAdmin: environment.admin,
			product: environment.product,
			addedBy: `${userInfo.firstname} ${userInfo.surname}`,
		});
	}

	fetchUserHistory(userId, entityID = this.entityId) {
		const historyCollection = this.afs.collection(`entities/${entityID}/users/${userId}/history`, ref => ref.orderBy('created', 'desc'));

		return historyCollection.valueChanges({ idField: 'id' }) as Observable<EntityUserHistoryEntry[]>;
	}

	fetchUserHistoryUser(userId) {
		const userDoc = this.afs.doc(`users/${userId}`);
		return userDoc.valueChanges();
	}

	updateUserPermission(userUID, permissions: any, entityID = this.entityId) {
		const data = {
			permissions: permissions,
		};

		this.permissionDoc = this.afs.collection('users').doc(userUID).collection('entities').doc(entityID);
		return this.permissionDoc.update(data).then(async () => {
			const managementUser = this.afs.collection(`entities/${entityID}/management/users/list`).doc(userUID).ref;
			return managementUser
				.get()
				.then(userDetails => {
					if (userDetails.exists) {
						this.updateManagementProfiles(userUID, data, entityID);
					}
				})
				.catch(function (error) {
					console.log('Error management user:', error);
				});
		});
	}

	updateManagementProfiles(userUID, data, entityID = this.entityId) {
		const managementUserRef = this.afs.collection('entities').doc(entityID).collection('management').doc('users').collection('list').doc(userUID);

		// CHECK IF USER PERMISSIONS EXIST
		if (data.permissions) {
			// IF USER HAS PERMISSIONS UPDATE MANAGEMENT TEAM

			// BUILD PROFILES
			let isAdmin = false;
			let isSchemeExecutive = false;
			let isViewOnly = false;

			const profiles = [];

			data.permissions.forEach(permission => {
				if (permission === 'admin') {
					isAdmin = true;
				} else if (permission === 'scheme_executive') {
					isSchemeExecutive = true;
				} else if (permission === 'view_only') {
					isViewOnly = true;
				} else if (permission === 'fin') {
					profiles.push('Finance');
				} else {
					profiles.push(permission);
				}
			});

			// FETCH USER DETAILS
			const userRef = this.afs.collection('users').doc(userUID).ref;
			return userRef
				.get()
				.then(userDetails => {
					if (userDetails.exists) {
						const userData = userDetails.data() as User;

						if (!userData.cell) {
							userData.cell = '';
						}

						// UPDATE MANAGEMENT TEAM
						managementUserRef.set(
							{
								active: true,
								firstname: userData.firstname,
								surname: userData.surname,
								email: userData.email,
								cell: userData.cell,
								ref: userRef,
								uid: userUID,
								isAdmin: isAdmin,
								isViewOnly: isViewOnly,
							},
							{ merge: true }
						);
					} else {
						console.log('No user found!');
					}
				})
				.catch(function (error) {
					console.log('Error getting user:', error);
				});
		}
	}

	removePropertyFromUser(userID: string, propertyID: string, type: string) {
		const userRef = this.afs.collection('users').doc(userID);
		const propertyRef = this.afs.collection('entities').doc(this.entityId).collection('properties').doc(propertyID);

		const deactivateUserProperty = userRef.collection('entities').doc(this.entityId).collection('properties').doc(propertyID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		const deactivatePropertyUser = propertyRef.collection('users').doc(userID).set(
			{
				active: false,
			},
			{ merge: true }
		);

		return Promise.all([deactivateUserProperty, deactivatePropertyUser]).then(() => {
			if (type === 'tenant') {
				// FETCH ALTERNATE TENANT DETAILS IF ONE EXISTS ELSE SET TO BLANK FOR TENANT TYPE
				propertyRef
					.collection('users', ref => ref.where('type', '==', 'tenant').where('active', '==', true))
					.snapshotChanges()
					.pipe(
						map(changes => {
							return changes.map(a => {
								const data = a.payload.doc.data() as User;
								data.uid = a.payload.doc.id;
								return data;
							});
						}),
						take(1)
					)
					.toPromise()
					.then(tenantCollection => {
						if (tenantCollection.length === 0) {
							propertyRef.set(
								{
									tenant: '',
								},
								{ merge: true }
							);
						} else {
							tenantCollection.forEach(tenant => {
								propertyRef.set(
									{
										tenant: tenant.firstname + ' ' + tenant.surname,
									},
									{ merge: true }
								);
							});
						}
					});
			} else {
				let typeObj = {};

				typeObj[type] = '';
				propertyRef.set(typeObj, { merge: true });
			}
		});
	}

	fetchPrimaryUser(userID) {
		const primaryUser = this.afs.doc(`entities/${this.entityId}/management/users/list/${userID}`);

		return primaryUser.valueChanges();
	}

	fetchPrimaryTaskWatcher(userID) {
		const primaryTaskWatcher = this.afs.doc(`entities/${this.entityId}/management/users/list/${userID}`);

		return primaryTaskWatcher.valueChanges();
	}

	fetchAddTeamMembersList(entityId, taskId, product) {
		const teamMembersList = this.afs
			.collection(`entities/${entityId}/tasks/${taskId}/teamMembers`, ref => ref.orderBy('memberUserName', 'asc'))
			.valueChanges({ idField: 'id' });
		const usersList = this.afs.collection(`entities/${entityId}/users`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true)).valueChanges({ idField: 'id' });
		const whitfieldsStaff = this.afs.collection(`entities/whitfields/users`, ref => ref.where('type', '==', 'staff')).valueChanges({ idField: 'id' });
		const managementTeamRef = this.afs.collection(`entities/${entityId}/management/users/list`, ref => ref.where('isAdmin', '==', true)).valueChanges({ idField: 'id' });
		return combineLatest<any[]>(teamMembersList, usersList, whitfieldsStaff, managementTeamRef).pipe(
			map(dataArrays => {
				let returnList = [];
				const plexList = [...dataArrays[0], ...dataArrays[3]];
				const whitfieldsList = [...dataArrays[0], ...dataArrays[1], ...dataArrays[2]];
				// get users
				if (product === 'whitfields') {
					returnList = Array.from(new Set(whitfieldsList.map(a => a.id))).map(id => {
						return whitfieldsList.find(a => a.id === id);
					});
					return returnList;
				} else {
					returnList = Array.from(new Set(plexList.map(a => a.id))).map(id => {
						return plexList.find(a => a.id === id);
					});
					return returnList;
				}

				// const teamMembersList = dataArrays[0];

				// // filter out whitfields list if already part of task team

				//     console.log("TCL: UsersService -> fetchAddTeamMembersList -> teamMembersList", teamMembersList)
				//     const usersList = dataArrays[1];
				//     const whitfieldsStaffList = dataArrays[2];

				//     teamMembersList.forEach(member => {

				//     console.log("TCL: UsersService -> fetchAddTeamMembersList -> member", member)
				//         teamMembers.push(member.memberUserId);
				//     });

				//     usersList.map(member => {
				//         if (teamMembersList.indexOf(member.id) === -1) {
				//             teamMembersList.push(member);
				//             addTeamListIdArray.push(member.id);
				//         }
				//     });

				//     whitfieldsStaffList.forEach(staff => {
				//         if (addTeamListIdArray.indexOf(staff.id) === -1) {
				//             addTeamMemberList.push(staff)
				//         }
				//     })

				//     console.log("TCL: UsersService -> fetchAddTeamMembersList -> addTeamMemberList", addTeamMemberList)
				//     return addTeamMemberList;
			})
		);
	}

	fetchManagementTeam() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	fetchManagers() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true).where('isSchemeManager', '==', true))
			.valueChanges({ idField: 'id' });
	}

	fetchBothExecutivesAndManagers() {
		const mergeById = ([t, s]) =>
			t.map(p =>
				Object.assign(
					{},
					p,
					s.find(q => p.id === q.id)
				)
			);
		const managers$ = this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true).where('isSchemeManager', '==', true))
			.valueChanges({ idField: 'id' });
		const executives$ = this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
		return combineLatest(managers$, executives$);
	}

	fetchOCC() {
		return this.afs.doc(`entities/${this.entityId}/WhitfieldsManagementTeam/occ`).valueChanges();
	}

	fetchAddWatchersList(entityID, taskId) {
		const watchersList = this.afs
			.collection<Watcher>(`entities/${entityID}/tasks/${taskId}/watchers`, ref => ref.orderBy('watcherUserName', 'asc'))
			.valueChanges({ idField: 'id' });
		const managementTeam = this.afs
			.collection(`entities/${entityID}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
		const allUsers = this.afs.collection(`entities/${entityID}/users`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true)).valueChanges({ idField: 'id' });

		return combineLatest<any[]>(watchersList, managementTeam, allUsers).pipe(
			map(dataArrays => {
				const watchers = [];
				const addWatcherList = [];
				const addWatcherListIds = [];

				const watcherList = dataArrays[0];
				const managementList = dataArrays[1];
				const allUsersList = dataArrays[2];

				watcherList.forEach((_member: any) => {
					watchers.push(_member.watcherUserId);
				});

				managementList.forEach((_member: any) => {
					if (!watchers.includes(_member.id) && !addWatcherList.includes(_member)) {
						addWatcherList.push(_member);
						addWatcherListIds.push(_member.id);
					}
				});

				allUsersList.forEach((_member: any) => {
					if (!watchers.includes(_member.id) && !addWatcherListIds.includes(_member.id)) {
						addWatcherList.push(_member);
					}
				});
				return addWatcherList;
			})
		);
	}

	fetchAdminInManagementTeam() {
		return this.afs
			.collection(`entities/${this.entityId}/management/users/list`, ref => ref.orderBy('firstname', 'asc').where('active', '==', true).where('isAdmin', '==', true))
			.valueChanges({ idField: 'id' });
	}

	// ADD INFO STEPS

	fetchEntityUser(uid, entityID = this.entityId) {
		const entityUserDetails = this.afs.doc(`users/${uid}/entities/${entityID}`);
		return entityUserDetails.valueChanges();
	}

	resendEntityInvite(userID: string) {
		const pendingEmailEntityInviteRef = this.afs.collection('pending');

		// FETCH USER DETAILS
		const userRef = this.afs.doc(`users/${userID}`).ref;

		return userRef.get().then(user => {
			const userData = user.data() as User;

			// BASE64 ENCODE USER DATA FOR EMAIL INVITE LINK
			const userDataToEncode = {
				firstname: userData.firstname,
				surname: userData.surname,
				email: userData.email.toLowerCase(),
				uid: userID,
				firebaseId: userData.firebaseId,
			};

			const encodedUserBase64Data = btoa(JSON.stringify(userDataToEncode));

			return pendingEmailEntityInviteRef.add({
				request: 'emailEntityInvites',
				entityId: this.entityId,
				userId: userID,
				environmentAdmin: environment.admin,
				environmentClient: environment.client,
				userBase64Data: encodedUserBase64Data,
				product: environment.product,
			});
		});
	}

	async sendAttorneySiteInvite(userId: string) {
		const pendingEmailEntityInviteRef = this.afs.collection('pending');

		const userRef = this.afs.doc<User>(`users/${userId}`).ref;
		const user = await userRef.get();
		const userData = user.data();

		// BASE64 ENCODE USER DATA FOR EMAIL INVITE LINK
		const userDataToEncode = {
			firstname: userData.firstname,
			surname: userData.surname,
			email: userData.email.toLowerCase(),
			uid: userId,
		};

		const userBase64Data = btoa(JSON.stringify(userDataToEncode));

		const pendingData = {
			request: 'emailAttorneySiteInvite',
			userId,
			userBase64Data,
			product: environment.product,
			created: new Date(),
			user: userDataToEncode,
			entityId: this.entityId,
		};

		return pendingEmailEntityInviteRef.add(pendingData);
	}

	resendAllEntityInvites(users: any) {
		const pendingEmailAllEntityInvitesRef = this.afs.collection('pending');

		users.forEach(user => {
			const userDataToEncode = {
				firstname: user.firstname,
				surname: user.surname,
				email: user.email.toLowerCase(),
				uid: user.uid,
			};
			user.userBase64Data = btoa(JSON.stringify(userDataToEncode));
		});

		return pendingEmailAllEntityInvitesRef.add({
			request: 'emailAllEntityInvites',
			entityId: this.entityId,
			users: users,
			environmentAdmin: environment.admin,
			environmentClient: environment.client,
			product: environment.product,
		});
	}

	resendVerificationRequest(user) {
		return this.afs.collection(`pending`).doc(user.firebaseId).set({
			request: 'emailVerificationRequests',
			email: user.email.toLowerCase(),
			firstname: user.firstname,
			uid: user.firebaseId,
			entityId: this.entityId,
			environmentAdmin: environment.admin,
			environmentClient: environment.client,
			source: 'client',
			product: environment.product,
			created: new Date(),
		});
	}

	//ACCOUNTS
	addUserToAccount(userData: User & { id?: string }, accountId: string, entityId = this.entityId, relationship: any) {
		return this.afs.doc(`entities/${entityId}/fin/debtors/list/${accountId}/users/${userData.id}`).set(
			{
				active: true,
				created: Date.now(),
				createdBy: this.authSrvc.userId,
				firstname: userData.firstname ? userData.firstname : '',
				occupant: relationship.occupant ? relationship.occupant : false,
				primaryAgent: relationship.primaryAgent ? relationship.primaryAgent : false,
				surname: userData.surname ? userData.surname : '',
				type: relationship.type ? relationship.type : '',
				userId: userData.id ? userData.id : '',
			},
			{ merge: true }
		);
	}

	addAccountToUser(userId: string, accountData: Account, entityId = this.entityId) {
		const userRef = this.afs.collection('entities').doc(entityId).collection('users').doc(userId).collection('accounts').doc(accountData.id);

		try {
			userRef.set(accountData, { merge: true });
			this.addAssociateAccountHistoryToUser(userId, accountData.name, entityId);
		} catch (error) {
			console.log('Error associating account to user:', error);
		}
	}

	private async addAssociateAccountHistoryToUser(userID: string, accountName: string, entityId?: string): Promise<void> {
		const changedField: HistoryAddedField = {
			field: 'Account',
			value: accountName,
			fieldAction: 'added',
		};

		this.addHistoryLogToUser([changedField], 'accounts', userID, true, entityId);
	}

	removeAccountFromUser(userId, accountId, account?) {
		const userRef = this.afs.collection('entities').doc(this.entityId).collection('users').doc(userId).collection('accounts').doc(accountId);

		return userRef.set({ active: false }, { merge: true }).then(() => {
			if (account && environment.product === 'whitfields') {
				this.removeAssociatedUserDetails(account.name, userId, 'Debtor Account', 'account');
			}
		});
	}

	getAccountsForUser(userId) {
		return this.afs
			.collection(`entities/${this.entityId}/users/${userId}/accounts`, ref => ref.orderBy('name', 'asc').where('active', '==', true))
			.valueChanges({ idField: 'id' });
	}

	getAllAccountsForUser(userId): Observable<Account[]> {
		return this.afs.collection(`entities/${this.entityId}/users/${userId}/accounts`, ref => ref.orderBy('name', 'asc')).valueChanges({ idField: 'id' });
	}

	// IMAGE UPLOAD
	uploadImage(upload = null, imageName: string, loggedInUser: User) {
		const fileName = `${new Date().getTime()}_${imageName}`;
		const path = `users/${loggedInUser.uid}/files/${fileName}.png`;
		const task = this.storage.upload(path, upload);
		const ref = this.storage.ref(path);

		return new Promise((resolve, reject) => {
			task.snapshotChanges()
				.pipe(
					finalize(() => {
						const downloadURL = ref.getDownloadURL();
						downloadURL.subscribe(url => {
							const file = {
								photoURL: url,
							};

							return this.saveUploadDataFirestore(file, loggedInUser)
								.then(() => {
									return resolve('');
								})
								.catch(err => {
									return reject('Failed to save data to firestore: ' + err);
								});
						});
					})
				)
				.subscribe();
		});
	}

	private saveUploadDataFirestore(file: any, loggedInUser: User) {
		return this.afs.doc(`users/${loggedInUser.uid}`).update(file);
	}

	getNumberOfAdmins() {
		const managementListRef = this.afs.collection(`entities/${this.entityId}/management/users/list`, ref => ref.where('isAdmin', '==', true));

		return managementListRef.valueChanges({ idField: 'id' });
	}

	sendNewAccountRequest(accountRequest: RequestAccount) {
		const pendingAdminSiteUserAddRequestRef = this.afs.collection(`pending`);

		accountRequest.request = 'adminSiteUserAddRequest';

		return pendingAdminSiteUserAddRequestRef
			.add(accountRequest)
			.then(() => {
				toastr.success('Your request has been submitted!');
			})
			.catch(error => {
				toastr.error('Request could not be submitted! Please try again.');
			});
	}

	updateUserId(userId) {
		this.userId = userId;
	}

	updateAssociatedUserDetails(userId, owner, changedFields, type) {
		const fields: Array<HistoryModifiedField | HistoryAddedField> = changedFields.map((field: HistoryAddedField): HistoryModifiedField | HistoryAddedField => {
			if (field.field === 'addType') {
				field.field = 'owner_type';
			}
			if (field.field === 'editPropertyType') {
				field.field = 'type';
			}
			if (field.field === 'editPropertyOccupant') {
				field.field = 'occupant';
			}
			if (owner[field.field]) {
				let permission: string = '';
				if (field.value !== 'alternate' && field.value !== 'primary') {
					permission = 'tenant';
				}

				if (field.value !== 'tenant') {
					permission = 'owner';
				}

				this.afs.doc(`users/${userId}/entities/${this.entityId}`).update({
					permissions: [permission],
				});

				return {
					field: field.field,
					from: owner[field.field],
					to: field.value,
					fieldAction: 'modified',
				} as HistoryModifiedField;
			} else {
				let permission: string = '';
				if (field.value !== 'alternate' && field.value !== 'primary') {
					permission = 'tenant';
				}

				if (field.value !== 'tenant') {
					permission = 'owner';
				}

				this.afs.doc(`users/${userId}/entities/${this.entityId}`).update({
					permissions: [permission],
				});
				return {
					field: field.field,
					value: field.value,
					fieldAction: 'added',
				} as HistoryAddedField;
			}
		});

		this.addHistoryLogToUser(fields, type, userId, true);
	}

	removeAssociatedUserDetails(value, userId, association, type) {
		const changedField: HistoryAddedField = {
			field: `Associated ${association} Removed`,
			value: `${value}`,
			fieldAction: 'removed',
		};
		this.addHistoryLogToUser([changedField], type, userId, true);
	}

	addAssociatedUserDetails(value, userId, association, type) {
		const changedField: HistoryAddedField = {
			field: `Associated ${association} Added`,
			value: `${value}`,
			fieldAction: 'added',
		};
		this.addHistoryLogToUser([changedField], type, userId, true);
	}

	fetchAllUserServiceProviders(userId) {
		return this.afs
			.collection('users')
			.doc(userId)
			.collection('entities', ref => ref.where('active', '==', true).where('entity_type', '==', 'Service Provider'))
			.valueChanges({ idField: 'uid' });
	}
}
